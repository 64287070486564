import React from "react"
import projectsStyles from '../../style/projects.module.scss'
import Arrow from "../../img/arrow.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"


const ProjectsCard = ({ bg, title, client, year, url }) => {

    const path = bg.props.src

    return (
        <AniLink cover bg="#F2F2F2" duration = {1} direction="up" className={projectsStyles.projectCard} to = {url}>
            <div className={projectsStyles.projectCardBg} style={{ backgroundImage: "url(" + path + ")" }}></div>
            
            <div className={projectsStyles.projectCardTxt}>
            <h3>{title}</h3>
            <p className={projectsStyles.year}>{year}</p>
            <p className={projectsStyles.client}>{client}</p>
            <img className={projectsStyles.arrow} src={Arrow} alt="-->" width="100%" />
            </div>
        </AniLink>

    )
}

export default ProjectsCard
